<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>Transactions</h3>
        </v-card-title>
        <v-card-text>
          <h3 v-if="transactions.length == 0"> 0 transactions found</h3>
        </v-card-text>

        <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        <v-data-table :headers="headers" :items="transactions" :custom-sort="customSort" >
                    <template v-slot:item.ValueDate="props">
              {{ props.item.ValueDate.length == 0 ? '-----': props.item.ValueDate}}
          </template>
          <template v-slot:item.ValueTime="props">
              {{ props.item.ValueTime.length == 0 ? '-----': props.item.ValueTime}}
          </template>
          <template v-slot:item.Amount="props">
              {{ props.item.Amount.length == 0 ? '-----': currencyAmount(props.item.Amount)}}
          </template>
          <template v-slot:item.UTRNO="props">
              {{ props.item.UTRNO.length == 0 ? '-----': props.item.UTRNO}}
          </template>
          <template v-slot:item.UniqueTransactionId="props">
              {{ props.item.UniqueTransactionId.length == 0 ? '-----': props.item.UniqueTransactionId}}
          </template>
          <template v-slot:item.VANNO="props">
              {{ props.item.VANNO.length == 0 ? '-----': props.item.VANNO}}
          </template>
          <template v-slot:item.CustomerName="props">
              {{ props.item.CustomerName.length == 0 ? '-----': props.item.CustomerName}}
          </template>
          <template v-slot:item.PayeeAccountNo="props">
              {{ props.item.PayeeAccountNo.length == 0 ? '-----': props.item.PayeeAccountNo}}
          </template>
          <template v-slot:item.TransactionType="props">
              {{ props.item.TransactionType.length == 0 ? '-----': props.item.TransactionType}}
          </template>
          <template v-slot:item.PayeeBankIFSCCode="props">
              {{ props.item.PayeeBankIFSCCode.length == 0 ? '-----': props.item.PayeeBankIFSCCode}}
          </template>
          <template v-slot:item.TransactionReferenceNo="props">
              {{ props.item.TransactionReferenceNo.length == 0 ? '-----': props.item.TransactionReferenceNo}}
          </template>
          <template v-slot:item.PayeeBankName="props">
              {{ props.item.PayeeBankName.length == 0 ? '-----': props.item.PayeeBankName}}
          </template>
          <template v-slot:item.PayeeBankBranchName="props">
              {{ props.item.PayeeBankBranchName.length == 0 ? '-----': props.item.PayeeBankBranchName}}
          </template>
          <template v-slot:item.PayeeBankBranchplace="props">
              {{ props.item.PayeeBankBranchplace.length == 0 ? '-----': props.item.PayeeBankBranchplace}}
          </template>
          <template v-slot:item.PayeeChequeNo="props">
              {{ props.item.PayeeChequeNo.length == 0 ? '-----': props.item.PayeeChequeNo}}
          </template>
          <template v-slot:item.PayeeChequeDate="props">
              {{ props.item.PayeeChequeDate.length == 0 ? '-----': props.item.PayeeChequeDate}}
          </template>
      </v-data-table>
      </v-card>
</v-container>
  </v-main>
</template>
<script>
import moment from 'moment';

export default {
  data() {
    return {
      transactions: [],
      listModel: '',
      keysArray: [],
      refinedKeysArray: [],
      error: {
        status: false,
        message: '',
      },
      success: {
        status: false,
        message: '',
      },
      formatLang: 'en-IN',
      formatStyle: 'currency',
      formatCurrency: 'INR',
      headers: [
        { text: 'Value Date', value: 'ValueDate', width: '108px' },
        { text: 'Value Time', value: 'ValueTime' },
        { text: 'Amount', value: 'Amount' },
        { text: 'UTR NO', value: 'UTRNO' },
        { text: 'Unique Transaction Id', value: 'UniqueTransactionId' },
        { text: 'VAN NO', value: 'VANNO' },
        { text: 'Customer Name', value: 'CustomerName' },
        { text: 'Payee Account No.', value: 'PayeeAccountNo' },
        { text: 'Transaction Type', value: 'TransactionType' },
        { text: 'Payee Bank IFSC Code', value: 'PayeeBankIFSCCode' },
        { text: 'Transaction Reference No.', value: 'TransactionReferenceNo' },
        { text: 'Payee Bank Name', value: 'PayeeBankName' },
        { text: 'Payee Bank Branch Name', value: 'PayeeBankBranchName' },
        { text: 'Payee Bank Branch place', value: 'PayeeBankBranchplace' },
        { text: 'Payee Cheque No.', value: 'PayeeChequeNo' },
        { text: 'Payee Cheque Date', value: 'PayeeChequeDate', width: '108px' },
      ],
    };
  },
  methods: {
    async getTransactions() {
      const myHeaders = new Headers();
      myHeaders.append('X-KURLON-APIKEY',
        '6SQgnwAzfxvHLV7TwtRqhZxHWaL1pLXK3ZCtcfdvJyYzHao03oiJyR96&MjQxYzY4MjAtOWMxNy00NzlmLTk4MGItNmZlNDM1MmFmYWM0');
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      const response = await fetch('https://fmw.kurlon.com/api/v1/axis-bank/transactions', requestOptions);
      const dataFetched = await response.json();
      if (dataFetched !== undefined) {
        if (dataFetched.length !== 0) {
          this.keysArray = Object.keys(dataFetched[0]);
          this.refinedKeysArray = JSON.parse(JSON.stringify(Object.keys(dataFetched[0])).replace(/ +?/g, ''));
          const rawInfo = JSON.parse(JSON.stringify(dataFetched));
          rawInfo.forEach((item) => {
            const dict = {};
            for (let i = 0; i < this.keysArray.length; i += 1) {
              const key = this.refinedKeysArray[i];
              dict[key] = item[this.keysArray[i]];
            }
            this.transactions.push(dict);
          });
        }
      }
    },
    dateHelpCompare(date1, date2) {
      return moment(date1, 'DD-MM-YYYY') > moment(date2, 'DD-MM-YYYY') ? -1 : 1;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'ValueDate') {
          if (!isDesc[0]) {
            return this.dateHelpCompare(a.ValueDate, b.ValueDate);
          }
          return this.dateHelpCompare(b.ValueDate, a.ValueDate);
        }
        if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    currencyAmount(amt) {
      return this.formatter.format(amt);
    },
  },
  computed: {
    formatter() {
      return new Intl.NumberFormat(this.formatLang, {
        style: this.formatStyle,
        currency: this.formatCurrency,
      });
    },
  },
  mounted() {
    this.getTransactions();
  },
};
</script>
